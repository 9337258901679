import { MenuCreateOptionInterface } from "kernel/types/module";
// @ts-ignore
import siteConfig from "@/config/site.js";
class menu {
  private vm: Vue;
  constructor() {
    this.vm = window.rootVm;
  }

  $t(args: string) {
    return this.vm.$t(args);
  }

  get(): { [key: string]: any } {
    const providerId = this.vm.$route.params.providerId; // 給provider-store layout用
    // 'picker', 'dispatcher', 'freightman'
    return {
      unauthProvider: [],
      provider: [
        {
          label: "供應商資料",
          group: [
            {
              label: "基本資料",
              route: {
                name: "provider-info",
              },
              allowedRoles: []
            },
            {
              label: "供應商連結 & QR Code",
              route: {
                name: "provider-line",
              },
              allowedRoles: []
            },
            // {
            //   label: '營業時間',
            //   route: {
            //     name: 'provider-business-hours',
            //   },
            // },
            {
              label: "line@固定訊息",
              route: {
                name: "provider-line-message-edit",
              },
              allowedRoles: []
            },
            {
              label: "邀請共同管理員",
              route: {
                name: "provider-send-invite",
              },
              allowedRoles: []
            },
            {
              label: "配送資訊",
              route: {
                name: "provider-shipping-info",
              },
              allowedRoles: []
            },
            {
              label: "金流設定",
              route: {
                name: "provider-payment-setting",
              },
              allowedRoles: []
            },
            // {
            //   label: '偏好物流商',
            //   route: {
            //     name: 'provider-favorite-logistic',
            //     params: { providerId },
            //   },
            //   allowedRoles: []
            // },
          ],
        },

        {
          label: "我的商品",
          group: [
            {
              label: "商品管理",
              route: {
                name: "product-list",
              },
              allowedRoles: []
            },
            {
              label: '今日價格',
              route: {
                name: 'provider-today-price',
              },
              allowedRoles: []
            },
            {
              label: "商品分類管理",
              route: {
                name: "product-menu",
              },
              allowedRoles: []
            },
            {
              label: "商品單位管理",
              route: {
                name: "product-unit",
              },
              allowedRoles: []
            },
            {
              label: '大量匯入商品',
              route: {
                name: 'product-import',
              },
              allowedRoles: []
            },
          ],
        },
        {
          label: "訂單紀錄",
          route: {
            name: "provider-orders",
          },
          allowedRoles: ['picker']
        },
        {
          label: "訂單派遣",
          route: {
            name: "provider-deliveries",
          },
          allowedRoles: ['dispatcher']
        },
        {
          label: '貨運列表',
          route: {
            name: 'provider-delivery-mans',
          },
          allowedRoles: ['freightman']
        },
        {
          label: '待辦事項',
          route: {
            name: "todo",
          },
          allowedRoles: []
        },
        {
          label: '退款列表',
          route: {
            name: "provider-refund-list",
          },
          allowedRoles: []
        },
        // {
        //   label: '銷售報表',
        //   group: [
        //     {
        //       label: '客戶月報表',
        //       route: {
        //         name: '',
        //       },
        //     },
        //     {
        //       label: '商品月報表',
        //       route: {
        //         name: '',
        //       },
        //     },
        //   ],
        // },
        // {
        //   label: "請款",
        //   route: {
        //     name: "apply-pay-overview",
        //   },
        //   allowedRoles: []
        // },
        {
          label: "客戶管理",
          route: {
            name: "client-overview",
          },
          allowedRoles: []
        },
        {
          label: "客戶分級",
          route: {
            name: "client-vipList",
          },
          allowedRoles: []
        },
        {
          label: "報表",
          route: {
            name: "provider-report-list",
          },
          allowedRoles: []
        },
        {
          label: "操作記錄",
          route: {
            name: "operation-record",
          },
          allowedRoles: []
        },
        {
          label: "切換供應商",
          route: {
            name: "provider-switch-identity",
          },
          allowedRoles: ['picker', 'dispatcher', 'freightman']
        },
        {
          label: "網站設定",
          route: {
            name: "provider-web",
          },
          allowedRoles: []
        },
        {
          label: "Line Notify 綁定",
          route: {
            name: "line-notify-relation",
          },
          allowedRoles: ['picker', 'dispatcher', 'freightman']
        },
      ],
      unauthStore: [],
      store: [
        {
          label: "商品清單",
          route: {
            name: "store-product-list",
            params: { providerId },
          },
          allowedRoles: []
        },
        {
          label: "常用購物清單",
          route: {
            name: "store-favorite",
            params: { providerId },
          },
          allowedRoles: []
        },
        {
          label: "帳號資料",
          group: [
            {
              label: "基本資料",
              route: {
                name: "store-info",
                params: { providerId },
              },
              allowedRoles: []
            },
            // {
            //   label: "供應商",
            //   route: {
            //     name: "store-cooperation-list",
            //     params: { providerId },
            //   },
            //   allowedRoles: []
            // },
          ],
        },
        {
          label: "訂單記錄",
          group: [
            {
              label: "訂單記錄",
              route: {
                name: "store-orders",
                params: { providerId },
              },
              allowedRoles: []
            },
            // {
            //   label: "全供應商訂單記錄",
            //   route: {
            //     name: "store-orders-all-providers",
            //     params: { providerId },
            //   },
            //   allowedRoles: []
            // },
          ],
        },
        // {
        //   label: "應付款",
        //   route: {
        //     name: "store-apply-pay-overview",
        //     params: { providerId },
        //   },
        //   allowedRoles: []
        // },
        // {
        //   label: "報表",
        //   route: {
        //     name: "store-report-list",
        //     params: { providerId },
        //   },
        //   allowedRoles: []
        // },
        // {
        //   label: '分析報表',
        //   group: [
        //     {
        //       label: '月明細表',
        //       route: {
        //         name: '',
        //         params: { providerId },
        //       },
        //     },
        //     {
        //       label: '單一商品趨勢圖',
        //       route: {
        //         name: '',
        //         params: { providerId },
        //       },
        //     },
        //   ],
        // },
        // {
        //   label: "切換商店",
        //   route: {
        //     name: "store-switch-identity",
        //     params: { providerId },
        //   },
        //   allowedRoles: ['signer']
        // },
        {
          label: "Line Notify 綁定",
          route: {
            name: "line-notify-store-relation",
            params: { providerId }
          },
          allowedRoles: ['signer']
        },
      ],
    };
  }
}

export default () => new menu().get();
